import React from "react"
import LoanCalculator from "../../../components/body/pages/personal-loan/personal-loan-calc"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"

const LoanCalculatorPage = () => (
  <Layout>
    <SEO
      canonical={"https://kuda.com/en-ng/personal-loan/"}
      title="Salary Loans | Quick and easy Salary Loans with Kuda | Kuda"
      description="Personal Loans with the Kuda App in Nigeria  | Fast Payment"
    />
    <LoanCalculator />
  </Layout>
)

export default LoanCalculatorPage
