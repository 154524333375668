import React, { useEffect, Fragment, useState } from "react"
import { scrollToElement } from "../../../utility/utils"
import EntryIllustration from "../../../../assets/img/loans-calc-illustration.inline.svg"
import CTA from "../general/cta"
import BadgesEntryFold from "../general/badgesEntryFold"
import { formatBalance } from "../../../../util/util"

const PersonalLoanCalculator = () => {
  const [loanLimit, setLoanLimt] = useState("150,000")
  const entryContent = {
    title: `Can you take a Kuda loan?`,
    subtitle: "Check your eligibility for a Kuda loan in a few steps.",
    illustration: <EntryIllustration />,
  }

  async function getFirebaseData(firebase) {
    const data = await firebase.FireBase()
    const firebaseTermLoans = firebase.getFirebaseTermLoans()
    if (firebaseTermLoans?.loanLimit) {
      const newValue = formatBalance(firebaseTermLoans?.loanLimit, 0)
      setLoanLimt(newValue)
    }

    return data
  }

  useEffect(() => {
    scrollToElement()
    window.addEventListener("scroll", function () {
      scrollToElement()
    })
    import("../../../../firebase").then(firebase => {
      getFirebaseData(firebase)
    })
  }, [])

  return (
    <Fragment>
      <BadgesEntryFold
        title={entryContent.title}
        subtitle={entryContent.subtitle}
        illustration={entryContent.illustration}
        isUniqueLink={true}
      />
      
      <CTA />
    </Fragment>
  )
}

export default PersonalLoanCalculator
